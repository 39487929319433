import request from '@/utils/request.js';

let game_id = location.pathname.split("/")[1]

function getUrl(){
    let url
    if(localStorage.getItem(`${game_id}_scriptUrl`)){
        url = localStorage.getItem(`${game_id}_scriptUrl`).split("phonocatstudio.com")[1]
    }else{
        let game_id = location.pathname.split("/")[1]
        url = `/api/${game_id}`
    }
    return url
}
    
export const getGameData = () =>
request("get", getUrl())
    .then(function (res) {
        return res.data;
    }).catch(error =>{
        return error
    });

