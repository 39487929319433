<template>
    <div class="bg">
        This page is not found.
    </div>
</template>

<script>
import { getGameData } from "@/apis/game.js";

export default {

    created() {
        this.game_id = this.$route.params.game_id;
        document.title = this.game_id
        this.checkGameStatus()
    },
    data(){
        return{
            game_id:""
        }
    },
    methods: {
         checkGameStatus() {
            let timer = setInterval((async () => {
                localStorage.setItem(`${this.game_id}_scriptUrl`, `https://test-api.phonocatstudio.com/api/${this.game_id}`);
                let data = await getGameData()
                // console.log(`404data`,data)
                if (data.login_background_image) {
                    clearInterval(timer);
                    this.$router.push(`/${this.game_id}/login`);
                }
            }), 5000);
        }
    }
}
</script>

<style lang="scss" scoped>
.bg {
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    font-size: 1.5rem;
    padding: 8vw;
}
</style>